.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12%;
  }
  
  .left-links {
    display: flex;
  }
  
  .right-links {
    display: flex;
    margin-left: auto;
  }
  
  .navigation a {
    color: black;
    text-decoration: none;
    margin-right: 10px;
  }
  
  .navigation a:hover {
    text-decoration: underline;
  }